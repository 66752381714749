import styled from 'styled-components'

export const BottomInfo = styled.div`
  display: inline-block;
  margin: 100px 0 0;
  padding: 15px 15px 0;
  text-align: center;
  p {
    margin: 0;
  }
  img {
    width: 100%;
  }

  @media(max-width: 400px) {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    img {
    width: 120px;
    }
  }
`

export const BottomImg = styled.div`
  margin: 0 0 20px;
`
