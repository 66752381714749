import React, { useContext, useEffect, useState } from 'react'
import {  Button, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getRequestAuth, postRequestAuth } from '../../request/request'
import InputMask from 'react-input-mask'
import { JWT, ME, REFRESH, RELOAD } from '../../reduser/Reduser'
import { ContextType } from '../../type/type'
import { AppContext } from '../../App'
import './style/myAccountStyles.scss';
import validatorServerErrors from '../../modules/ValidatorServerErrors/ValidatorServerErrors'

type FieldType = {
    username?: string
    password?: string
    remember?: string
  }

const AuthSignIn = () => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const [validationPhone] = Form.useForm()
    const [sendSmsCode] = Form.useForm()
    const [newPassword] = Form.useForm()
    const [registration] = Form.useForm()
    const [login] = Form.useForm()
    const { state, dispatch } = useContext<ContextType>(AppContext)
    const [validateErrors, setValidateErrors] = useState<any>({})
    const navigate = useNavigate()
    const phoneMask = '+375(99)999-99-99'

  window.addEventListener('storageChanged', () => {
    setReload((prev) => !prev)
  })

  useEffect(() => {
    const jwt = localStorage.getItem('jwt')
    if (jwt) {
      getRequestAuth('/profile/about_me/')
        .then((res: any) => {
          dispatch({
            type: ME,
            payload: res.data,
          })
        })
        .catch((err) => {
          dispatch({
            type: JWT,
            payload: null,
          })
          navigate('/')
          // validatorServerErrors(err.data)
        })
    }
  }, [reload, state.reload])

  const refreshForm = () => {
    validationPhone.resetFields()
    sendSmsCode.resetFields()
    newPassword.resetFields()
    registration.resetFields()
    login.resetFields()
    setValidateErrors({})
  }

  const handleCancel = () => {
    setValidateErrors({})
    refreshForm()
    navigate('/');
  }

  const clearAuthorization = () => {
    localStorage.removeItem('jwt')
    localStorage.removeItem('refresh')
  }

  const onFinish = (values: any, type?: string) => {
    console.log(type)
    const body = {
      ...values,
      phone: values?.phone?.replace(/[^\d+]/g, ''),
    }
    setIsLoading(true)

    if (type === 'login') {
      clearAuthorization()
      postRequestAuth('/auth/login/', body)
        .then((res: any) => {
          setReload((e) => !e)
          localStorage.setItem('jwt', res?.data?.access_token)
          localStorage.setItem('refresh', res?.data?.refresh_token)

          dispatch({
            type: JWT,
            payload: res?.data?.access_token,
          })

          dispatch({
            type: REFRESH,
            payload: res?.data?.refresh_token,
          })

          handleCancel()
          setIsLoading(false)
        })
        .catch((err) => {
          clearAuthorization()
          setIsLoading(false)
          validatorServerErrors(err.data, setValidateErrors)
        })
    }
    }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const goToRegistration = () => {
    console.log('goToRegistration')
  }

  const goToForgot = () => {
    console.log('goToForgot')
  }

  return (
    <>
        <h2 style={{ color: '#4f682a', margin: 0, textAlign: 'center', marginTop: '20px' }}>{t('h90')}</h2>
        <Form
          form={login}
          name="login"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={(e) => onFinish(e, 'login')}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className='auth-form'
        >
          <Form.Item
            label={t('h56')}
            name="phone"
            rules={[{ required: true, message: `${t('h48')} ${t('h64')}` }]}
            validateStatus={validateErrors?.phone ? 'error' : ''}
            help={validateErrors?.phone}
          >
            <InputMask mask={phoneMask}>
              <Input />
            </InputMask>
          </Form.Item>

          <Form.Item<FieldType>
            label={t('h54')}
            name="password"
            rules={[{ required: true, message: `${t('h48')} ${t('h50')}` }]}
            validateStatus={validateErrors?.password ? 'error' : ''}
            help={validateErrors?.password}
          >
            <Input.Password />
          </Form.Item>

            <div style={{ textAlign: 'left' }}>
                <span onClick={goToRegistration} className="cursor-pointer" style={{ color: '#4f682a', fontWeight: '600' }}>
                  {t('h59').toUpperCase()}
                </span>
            </div>

          <Form.Item style={{ textAlign: 'center' }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: '#768A46', borderColor: '#768A46', width: '100%', height: '48px', fontSize: '16px', marginTop: '24px', borderRadius: '6px' }}
              className="color-2"
              loading={isLoading}
            >
              {t('h47')}
            </Button>
          </Form.Item>

            <div style={{ textAlign: 'center' }}>
                <span onClick={goToForgot} className="cursor-pointer" style={{ color: '#4f682a', textDecoration: 'underline' }}>
                  {t('h52')}
                </span>
            </div>
        </Form>
    </>
  )
}

export default AuthSignIn
