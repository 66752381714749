import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, Input, InputNumber, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getRequestAuth, postRequestAuth } from '../../request/request'
import InputMask from 'react-input-mask'
import { JWT, ME, REFRESH, RELOAD } from '../../reduser/Reduser'
import { ContextType } from '../../type/type'
import { AppContext } from '../../App'
import './style/myAccountStyles.scss';
import validatorServerErrors from '../../modules/ValidatorServerErrors/ValidatorServerErrors'

type FieldType = {
  username?: string
  password?: string
  remember?: string
}

const Forgot = () => {
  const { t } = useTranslation()
  const [step, setStep] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [reload, setReload] = useState(false)
  const [validationPhone] = Form.useForm()
  const [sendSmsCode] = Form.useForm()
  const [newPassword] = Form.useForm()
  const [registration] = Form.useForm()
  const [login] = Form.useForm()
  const { state, dispatch } = useContext<ContextType>(AppContext)
  const [validateErrors, setValidateErrors] = useState<any>({})
  const navigate = useNavigate()
  const phoneMask = '+375(99)999-99-99'

  window.addEventListener('storageChanged', () => {
    setReload((prev) => !prev)
  })

  useEffect(() => {
    const jwt = localStorage.getItem('jwt')
    if (jwt) {
      getRequestAuth('/profile/about_me/')
        .then((res: any) => {
          dispatch({
            type: ME,
            payload: res.data,
          })
        })
        .catch((err) => {
          dispatch({
            type: JWT,
            payload: null,
          })
          navigate('/')
          // validatorServerErrors(err.data)
        })
    }
  }, [reload, state.reload])

  const refreshForm = () => {
    validationPhone.resetFields()
    sendSmsCode.resetFields()
    newPassword.resetFields()
    registration.resetFields()
    login.resetFields()
    setValidateErrors({})
  }

  const showModalSignIn = () => {
    setStep(1)
    refreshForm()
  }

  const handleCancel = () => {
    setStep(1)
    setValidateErrors({})
    refreshForm()
    navigate('/');
  }

  const onFinish = (values: any, type?: string) => {
    const body = {
      ...values,
      phone: values?.phone?.replace(/[^\d+]/g, ''),
    }
    setIsLoading(true)

    if (type === 'new-password') {
      postRequestAuth('/auth/set_new_password/', body)
        .then((res) => {
          setIsLoading(false)
          message.success(res?.data?.status)
          handleCancel()
        })
        .catch((err) => {
          setIsLoading(false)
          validatorServerErrors(err.data, setValidateErrors)
        })
    }

    if (type === 'send_sms_code') {
      postRequestAuth('/auth/send_sms_code/', body)
        .then((res) => {
          setIsLoading(false)
          setValidateErrors({})
          newPassword.setFieldsValue({
            phone: body.phone,
            code: res.data.code,
          })
          message.success(res?.data?.status)
          setStep(2)
        })
        .catch((err) => {
          setIsLoading(false)
          validatorServerErrors(err.data, setValidateErrors)
        })
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const handleNewCode = (form: any) => {
    const phone = form?.getFieldInstance('phone')?.props?.value?.replace(/[^\d+]/g, '') || ''

    if (!phone && phone.length === 0) {
      message.error(`${t('h92')} - ${t('h56')}`)
      return false
    }

    postRequestAuth('/auth/send_sms_code/', { phone })
      .then((res) => {
        setIsLoading(false)
        setValidateErrors({})
        newPassword.setFieldsValue({
          phone,
        })
        setValidateErrors({})
        message.success(res?.data?.status)
      })
      .catch((err) => {
        setIsLoading(false)
        validatorServerErrors(err.data, setValidateErrors)
      })
  }

  return (
      <>
       <h2 style={{ color: '#4f682a', margin: 0, textAlign: 'center', marginTop: '20px' }}>{t('h88')}</h2>
        {step === 1 && (
          <Form
            form={sendSmsCode}
            name="send_sms_code"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={(e) => onFinish(e, 'send_sms_code')}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className='auth-form'
          >
            <Form.Item
              label={t('h56')}
              name="phone"
              rules={[{ required: true, message: `${t('h48')} ${t('h64')}` }]}
              validateStatus={validateErrors?.phone ? 'error' : ''}
              help={validateErrors?.phone}
            >
              <InputMask mask={phoneMask}>
                <Input />
              </InputMask>
            </Form.Item>

            <Form.Item style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                loading={isLoading}
                htmlType="submit"
                style={{ backgroundColor: '#768A46', borderColor: '#768A46', width: '100%', height: '48px', fontSize: '16px', marginTop: '24px', borderRadius: '6px' }}
              >
                {t('h60').toUpperCase()}
              </Button>
            </Form.Item>
          </Form>
        )}

        {step === 2 && (
          <Form
            form={newPassword}
            name="basic"
            layout="vertical"
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={(e) => onFinish(e, 'new-password')}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className='auth-form'
          >
            <Form.Item<FieldType>
              label={t('h49')}
              name="email"
              rules={[{ required: true, type: 'email', message: `${t('h48')} ${t('h49')}` }]}
              validateStatus={validateErrors?.email ? 'error' : ''}
              help={validateErrors?.email}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('h56')}
              name="phone"
              validateTrigger="onBlur"
              rules={[{ required: true, message: `${t('h48')} ${t('h64')}` }]}
              validateStatus={validateErrors?.phone ? 'error' : ''}
              help={validateErrors?.phone}
            >
              <InputMask mask={phoneMask}>
                <Input />
              </InputMask>
            </Form.Item>
            <Form.Item<FieldType>
              label={t('h54')}
              name="new_password"
              rules={[
                { required: true, message: `${t('h48')} ${t('h50')}` },
                { min: 8, message: 'Минимальное кол-во символов 8' },
              ]}
              validateStatus={validateErrors?.new_password ? 'error' : ''}
              help={validateErrors?.new_password}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              label={t('h55')}
              dependencies={['new_password']}
              validateStatus={validateErrors?.confirm_password ? 'error' : ''}
              help={validateErrors?.confirm_password}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: `${t('h68')} ${t('h50')}`,
                },
                { min: 8, message: 'Минимальное кол-во символов 8' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('new_password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error(t('h69')))
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item<FieldType>
              label={t('h57')}
              name="code"
              style={{ marginBottom: 5 }}
              rules={[
                { required: true, message: `${t('h48')} ${t('h57')}` },
                {
                  pattern: /^[0-9]+$/,
                  message: t('h93'),
                },
              ]}
              validateStatus={validateErrors?.code ? 'error' : ''}
              help={validateErrors?.code}
            >
              <InputNumber style={{ width: 200 }} />
            </Form.Item>
            <span className="cursor-pointer" onClick={() => handleNewCode(newPassword)} style={{ color: '#4f682a' }}>
              {t('h72')}
            </span>
            <br />
            <br />
            <Form.Item style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                loading={isLoading}
                htmlType="submit"
                style={{ backgroundColor: '#768A46', borderColor: '#768A46', width: '100%', height: '48px', fontSize: '16px', marginTop: '24px', borderRadius: '6px' }}
              >
                {t('h89').toUpperCase()}
              </Button>
            </Form.Item>

            <div style={{ textAlign: 'center' }}>
              <span className="cursor-pointer" onClick={showModalSignIn} style={{ color: '#4f682a', fontWeight: 600 }}>
                {t('h47').toUpperCase()}
              </span>
            </div>
          </Form>
        )}
     </>
  )
}

export default Forgot
