import React, { useContext } from 'react'
import { ContextType } from '../../type/type'
import { useCookies } from 'react-cookie'
import i18next from 'i18next'
import { Radio, Space } from 'antd'
import { WrapperLanguage } from './style/WrapperLanguageStyle'
import { AppContext } from '../../App'
import { LANG } from '../../reduser/Reduser'

const Language = () => {
  const { dispatch, isMobileWidth } = useContext<ContextType>(AppContext)
  const [cookie, setCookie]: any = useCookies(['lang'])

  const handleChange = (e: any) => {
    i18next.changeLanguage(e.target.value)
    setCookie('lang', e.target.value)
    dispatch({
      type: LANG,
      payload: e.target.value,
    })
  }

  return (
    <WrapperLanguage>
      <Radio.Group onChange={handleChange} defaultValue={cookie.lang || 'ru'}>
        <Space direction={isMobileWidth ? "horizontal" : "vertical"}>
          <Radio.Button value="en">EN</Radio.Button>
          <Radio.Button value="de">DE</Radio.Button>
          <Radio.Button value="ru">RU</Radio.Button>
        </Space>
      </Radio.Group>
    </WrapperLanguage>
  )
}

export default Language
