import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd'
import {  MenuOutlined, CloseOutlined } from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom'
import { listHeaderMenu } from '../TopMenu/list/listHeaderMenu'
import { useTranslation } from 'react-i18next'
import MySearch from '../MySearch/MySearch'
import { ReactComponent as LogoIcon } from '../../static/logo.svg'
import MyAccount from '../MyAccount/MyAccount'
import './style/styleBoxMobile.scss'
import { NavLink } from 'react-router-dom'
import Language from '../Language/Language';
import IncreaseMobile from '../IncreaseMobile/IncreaseMobile';
import { listMenu } from '../Menu/list/listMenu'



const HeaderBoxMobile = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false);
  const [navTitle, setNavTitle] = useState<string | null>(null)

  useEffect(() => {
      setNavTitle(location?.state?.breadcrumbs ?? null);
  }, [location])
  
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="box-mobile-wrapper">
      <div className="box-mobile-header">
        <Link to="/" className="box-mobile-link">
          <LogoIcon className="box-mobile-logo" />
        </Link>
        <div className="box-mobile-buttons">
          <MySearch />
          <MyAccount />
          <>
            <MenuOutlined className="box-mobile-menu" onClick={showDrawer}/>
            <Drawer onClose={onClose} open={open} width={320} className='test'>
              <div>
                <div className="box-mobile-button-close" onClick={onClose}><CloseOutlined /></div>
                <h3 className="box-mobile-title">{t('h139')}</h3>
                <Language/>
                  {listHeaderMenu(t).map(({ id, to, title }: any) => ( 
                    <div key={id} className='box-mobile-item'>
                      <NavLink state={{ breadcrumbs: id === 6 ? t('h216') : title }} to={to} onClick={() => setOpen(false)} className="box-mobile-link">
                        {
                          id === 6 ? <span className='box-mobile-club'> {t('h216')}{title}</span> : title
                        }
                      </NavLink>
                    </div>
                  ))}
              </div>
              <IncreaseMobile></IncreaseMobile>
            </Drawer>
          </>
        </div>
      </div>
      <div className='top-menu'>
        {listMenu(t).map(({ key, label }: any) => ( 
            <span className='top-menu-nav' key={key}>{label}</span>
          ))}
      </div>
      { navTitle &&   <div className='box-mobile-route-panel'>{ navTitle }</div> }
    </div>
  )
}

export default HeaderBoxMobile
