import styled from 'styled-components'

export const WrapperUl = styled.ul`
  margin: 0;
  padding: 0;
  font-size: 40px;
  li {
    list-style: none;
    display: inline-block;
    margin: 0 20px 0 0;
    &:last-child {
      margin: 0;
    }
    a {
      color: #fff;
      transition: 0.5s;
      &:hover {
        color: #000;
        transition: 0.5s;
        svg {
          path {
            stroke: #000;
          }
        }
      }
    }
  }


  @media (max-width: 400px) {
   .anticon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 24px;
      background-color: white;
      font-size: 20p;
      color: #4A552E;
      font-size: 28px;
       svg {
          path {
            stroke: #4A552E;
          }
        }
    }
        li {
        padding-right: 6%
        }
  }
`
