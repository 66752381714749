import React, { useContext, useState } from 'react'
import Search from 'antd/lib/input/Search'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ContextType } from '../../type/type'
import { AppContext } from '../../App'
import { RELOAD } from '../../reduser/Reduser'
import { SearchOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import style from './style/style.module.scss';

const MySearch = () => {
  const { t } = useTranslation()
  const { state, dispatch, isMobileWidth } = useContext<ContextType>(AppContext)
  const navigate = useNavigate()
  const [openSearch, setSearchOpen] = useState(false);

  const showSearch = () => {
    setSearchOpen(true)
  }

  const onSearchClose = () => {
    setSearchOpen(false);
  };

  const onSearch = (e: any) => {
    if (!e) return
    dispatch({
      type: RELOAD,
      payload: !state.reload,
    })
    onSearchClose()
    navigate(`/search?search=${e}`)
  }

  return <>
    {
      isMobileWidth ?
      <>
        <SearchOutlined className={style.search} onClick={showSearch}/>
          <Modal title={t('h45')} open={openSearch} onCancel={onSearchClose} footer={false}>
            <Search placeholder={t('h45')} allowClear onSearch={onSearch} max="50" min="3" />
          </Modal>
      </>
      :
      <Search placeholder={t('h45')} allowClear onSearch={onSearch} style={{ width: 200 }} max="50" min="3" />
    }
  </>
}

export default MySearch
