import styled from 'styled-components'

export const WrapperLanguage = styled.div`
  .ant-radio-button-wrapper {
    color: #fff;
    border: none;
    padding: 0;
    background: transparent;
    &:hover {
      border-bottom: 1px solid #cd0000;
    }
    &:focus,
    &:focus-within,
    &:active {
      color: #fff;
      border: none;
      box-shadow: none;
    }
    &.ant-radio-button-wrapper-checked {
      border-bottom: 1px solid #cd0000;
      border-radius: 0;
      &:hover {
        color: #cd0000;
      }
    }
  }

  @media(max-width: 400px) {
    border-bottom: solid #E4E5E0 1px;
    .ant-radio-button-wrapper {
      color: #768A46;
      width: 55px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.ant-radio-button-wrapper-checked {
        border-bottom: 4px solid #768A46 !important;
        background-color: #768A461A;
      }
      &:focus,
      &:focus-within,
      &:active {
        color: #768A46 !important;
      }
    }
  }
`
