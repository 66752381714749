import React, { useEffect, useState } from 'react'
import Icon, { CloseOutlined } from '@ant-design/icons'
import { ReactComponent as GlassesIcon } from '../../static/icon/glasses.svg'
import { useTranslation } from 'react-i18next'
import style from './style/style.module.scss';

const IncreaseMobile = () => {
  const list = [
    {
      id: 1,
      classEl: 'n1',
      size: 16,
      tooltip: 'Масштаб 100%',
      value: 100,
    },
    {
      id: 2,
      classEl: 'n2',
      size: 20,
      tooltip: 'Масштаб 125%',
      value: 125,
    },
    {
      id: 3,
      classEl: 'n3',
      size: 24,
      tooltip: 'Масштаб 150%',
      value: 150,
    },
  ]
  
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
   const [activeElement, setActiveElement] = useState(list[Number(localStorage.getItem('increase')) - 1] || list[0])
  const [percent, setPercent] = useState<number>(100);
  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

    useEffect(() => {
      const local = localStorage.getItem('increase') || 1
      list.forEach(({ id, classEl }) => {
        if (+id === +local) {
          document.body.id = classEl
        }
      })
    }, [])

    const increase = () => {
        let i = list.findIndex(item => item.id === activeElement.id);
        if (i === 2) {
            return;
        }
        ++i;
        setActiveElement(list[i])
        localStorage.setItem('increase', String(list[i].id))
        document.body.id = list[i].classEl;
    }

    const decrease = () => {
        let i = list.findIndex(item => item.id === activeElement.id);
        if (i === 0) {
            return;
        }
        --i;
        setActiveElement(list[i]);
        localStorage.setItem('increase', String(list[i].id))
        document.body.id = list[i].classEl;
    }

    return (
        <>
            <div className={style.impaired} onClick={openModal}>
                <Icon component={GlassesIcon} style={{fontSize: 22}}></Icon>
                {t('h215')}
            </div>
            { showModal && (
                <div className={style.impairedWrapper}>
                    <div className={style.impairedModal}>
                        <div className={style.impairedItem} style={{fontSize: 16}} onClick={decrease}>A</div>
                        <div className={style.impairedItem}>{ activeElement.value }%</div>
                        <div className={style.impairedItem} style={{fontSize: 26}} onClick={increase}>A</div>
                    </div>
                    <div className={style.impairedClose} onClick={closeModal}><CloseOutlined /></div>
                </div>
            )}
        </>
    )
}

export default IncreaseMobile