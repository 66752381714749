import moment from 'moment'

export const validateAge = (date: Date, minAge = 18, t: any): Promise<void> => {
  if (!date) return Promise.resolve()

  const age = moment().diff(date, 'years')
  if (age >= minAge) {
    return Promise.resolve()
  }

  return Promise.reject(new Error(`${t('h211')} ${minAge} ${t('h212')}`))
}
