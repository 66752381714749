import React, { createContext, useEffect, useReducer, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { appReducer, initialState } from './reduser/Reduser'
import 'antd/dist/antd.min.css'
import './style/antd.scss'
import './App.scss'
import 'moment/locale/ru'
import 'moment/locale/en-au'
import 'moment/locale/de'
import { ConfigProvider } from 'antd'
import ru_RU from 'antd/lib/locale/ru_RU'
import en_EN from 'antd/lib/locale/en_US'
import de_DE from 'antd/lib/locale/de_DE'
import IndexAdmin from './room/Admin/IndexAdmin'
import IndexPage from './pages/IndexPage'
import SignIn from './pages/Authorization/SignIn/SignIn'
import { Helmet } from 'react-helmet'

moment.locale('ru_RU')
import moment from 'moment'
import { YMInitializer } from 'react-yandex-metrika'

export const AppContext = createContext({})

function App() {
  const [isMobileWidth, setIsMobileWidth] = useState(window.innerWidth <= 400);
  const [state, dispatch] = useReducer(appReducer, initialState)
  const value = { state, dispatch, isMobileWidth }

  const renderPage = (state: any) => {
    const { role } = state || {}

    if (window.location.pathname === '/room' && !role) return <SignIn />

    switch (role) {
      case 'admin':
        return <IndexAdmin />
      default:
        return <IndexPage />
    }
  }

  const language = () => {
    process.env.REACT_APP_LANGUAGE = state.lang
    switch (state.lang) {
      case 'ru':
        return ru_RU
      case 'de':
        return de_DE
      case 'en':
        return en_EN
      default:
        return ru_RU
    }
  }

  useEffect(() => {
    const handleResize = () => setIsMobileWidth(window.innerWidth <= 400);
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);

  return (
    <BrowserRouter>
      <ConfigProvider locale={language()}>
        <YMInitializer
          accounts={[90583732]}
          options={{ defer: true, clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true }}
          version="2"
        />
        <Helmet htmlAttributes={{ lang: state?.lang || 'ru' }} />
        <AppContext.Provider value={value}>{renderPage(state)}</AppContext.Provider>
      </ConfigProvider>
    </BrowserRouter>
  )
}

export default App
