import styled from 'styled-components'

export const Header = styled.div`
  //position: fixed;
  //width: 100%;
  //top: 0;
  //z-index: 1;
  padding: 30px 0 0;
  background-color: #283018ed;
`

export const TopHeader = styled.div`
  padding: 0 0 35px;
`

export const WrapperTextPage = styled.div`
  padding: 0 0 35px;
`

export const AdditionMenu = styled.div`
  font-size: 18px;

  .menu-mobile {
    display: none;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 15px 0 0;

    &:last-child {
      margin: 0;
    }

    a {
      color: #fff;
      text-decoration: none;

      &.active {
        text-decoration: underline;
        text-decoration-color: #ff4d4f;
      }
    }
  }

  @media (max-width: 1199px) {
    margin-top: 50px;
  }
  @media (max-width: 767px) {
    margin-top: 10px;
  }
  @media (max-width: 738px) {
    .menu-mobile {
      display: inline-block;
    }
    ul {
      opacity: 0;
      justify-content: normal;
      height: 0;
      overflow: hidden;
      transition: opacity 0.5s ease, height 0.5s ease;
      &.active {
        display: inline-block;
        opacity: 1;
        height: auto;
      }
      li {
        display: inline-block;
        width: 100%;
        margin: 5px 0;
      }
    }
  }
  //@media (max-width: 585px) {
  //  ul {
  //    opacity: 0;
  //    justify-content: normal;
  //    height: 0;
  //    overflow: hidden;
  //    transition: opacity 0.5s ease, height 0.5s ease;
  //    &.active {
  //      display: inline-block;
  //      opacity: 1;
  //      height: auto;
  //    }
  //    li {
  //      display: inline-block;
  //      width: 100%;
  //      margin: 5px 0;
  //    }
  //  }
  //}
`

export const HeaderMenu = styled.div`
  padding: 30px;
  text-align: center;
  background-color: rgba(21, 28, 11, 0.78);
`

export const WrapperContent = styled.div`
  padding: 30px 15px;
  border-radius: 6px;

  @media (max-width: 400px) {
    padding: 0;
    border-radius: 6px;
  }
`

export const Footer = styled.footer`
  position: relative;
  z-index: -1;
  padding: 55px 0;
  color: #fff;
  background-color: #2b3123;

  @media (max-width: 400px) {
    padding: 22px 16px;
    background-color: #4A552E;
  }
`

export const TopFooter = styled.footer`
  padding-bottom: 55px;
  border-bottom: 2px solid #515749;
`

export const BottomFooter = styled.footer`
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1140px;
  h3 {
    color: #fff;
  }

  @media (max-width: 400px) {
    padding: 0;
  }
`

export const Box = styled.div`
  padding: 30px 16px;
  background-color: #fff;
  border-radius: 12px;

  &.loading {
    span {
      font-size: 18px;
      margin: 0 15px 0 0;
      vertical-align: middle;
    }
  }
`

export const Title = styled.h3<{ size?: string; color?: string }>`
  margin: 0 0 20px;
  color: ${({ color }) => (color ? `#${color}` : '#333')};
  font-size: ${({ size }) => (size ? `${size}px` : '30px')};
`

export const WrapperData = styled.div`
  position: relative;
  height: 100%;
  min-height: 300px;
  padding: 30px;
  word-wrap: break-word;
  background-color: #fff;
  img {
    max-width: 100%;
    width: auto !important;
  }
  @media (max-width: 400px) {
    padding: 16px;
    background-color: unset;
  }
`
